<template>
	<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
		<div class="container">
			<div class="row align-items-center justify-content-center">
				<div class="col-8 col-sm-12 col-md-6 offset-xl-2 offset-md-1 order-md-2">
				</div>
				<div class="col-12 col-md-5 col-xl-4 order-md-1 my-5" ref="loginForm">
					<!-- Heading <h1 class="display-4 text-center mb-3">{{$t('Register')}}</h1>-->

					<img src="@/assets/source/logo-white.svg" alt="..." class="img-fluid mb-2"/>

					<!-- Subheading -->
					<p class="mb-5 color-white">{{ $t('for your BooLink account') }}</p>

					<b-alert variant="danger" :show="!!errors.recaptcha">
						<div v-html="errors.recaptcha"></div>
					</b-alert>

					<b-alert variant="danger" :show="!!generalError">
						<div v-html="generalError"></div>
					</b-alert>

					<!-- Email address -->
					<form id="register-form" autocomplete="off">
						<div class="form-group">
							<label class="color-white">Username</label>
							<input
								autocomplete="false"
								type="text"
								tabindex="1"
								autofocus
								v-model="newUser.username"
								class="form-control"
								placeholder="Username"
								required
								:class="{ 'is-invalid': errors.username }"
							/>
							<div class="invalid-feedback text-white" v-if="errors.username">
								{{ errors.username }}
							</div>
						</div>

						<div class="form-group">
							<label class="color-white">Email</label>
							<input
								autocomplete="false"
								type="email"
								tabindex="2"
								autofocus
								v-model="newUser.email"
								class="form-control"
								placeholder="info@boolink.com"
								required
								:class="{ 'is-invalid': errors.email }"
							/>
							<div class="invalid-feedback text-white" v-if="errors.email">
								{{ errors.email }}
							</div>
						</div>

						<div class="form-group">
							<label class="color-white">{{ $t('Password') }}</label>
							<password-viewable
								v-model="newUser.password"
								:placeholder="$t('Password')"
								:tabindex="3"
								:isInvalid="errors.password"
							></password-viewable>
						</div>

<!--						<div class="form-group">
							<label class="color-white">{{ $t('Repeat Password') }}</label>
							<password-viewable
								v-model="newUser.repeat_password"
								:placeholder="$t('Repeat Password')"
								:tabindex="3"
								:isInvalid="errors.repeat_password"
							></password-viewable>
						</div>-->

						<div class="form-group">
							<b-form-checkbox
								id="termsCheckbox"
								name="termsCheckbox"
								class="text-white"
								:value="true"
								:unchecked-value="false"
								v-model="newUser.termAndConditions"
								:state=" errors.length && !(!!errors.termAndConditions)"
								required
							>
								I accept the <a href="https://boo.link/s/terms-of-use" class="text-white text-decoration-underline" target="_blank">terms of use</a>
								<b-form-invalid-feedback :state="!(!!errors.termAndConditions)" class="text-white">{{errors.termAndConditions}}</b-form-invalid-feedback>
							</b-form-checkbox>
						</div>

						<button-loading
							tabindex="4"
							class="btn btn-lg btn-block btn-primary mb-3 custom-btn"
							type="submit"
							:loading="loading"
							@click.prevent="createUser"
						>
							{{ $t('Register') }}
						</button-loading>

						<div class="text-center">
							<router-link
								style="display: inherit;"
								class="form-text small color-white"
								:to="{ name: 'login'}"
							>{{ $t('Already have an account?') }}
							</router-link>
						</div>
					</form>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters
} from 'vuex';
import PasswordViewable from '@/components/PasswordViewable.vue'
import ButtonLoading from '@/components/ButtonLoading'


export default {
	components: {
		PasswordViewable,
		ButtonLoading
	},
	data() {
		return {
			loading: false,
			newUser: {},
			errors: {},
			errorResponse: {},
			successResponse: {},
			generalError: null
		}
	},
	computed: {},
	created() {
		if (this.$route.query.username)
			this.newUser.username = this.$route.query.username;
	},
	methods: {
		...mapActions('users', ['create']),
		async createUser() {
			await this.$recaptchaLoaded()
			const token = await this.$recaptcha('register')
			this.loading = true;
			this.newUser.token = token;
			this.create([this.newUser]).then( res => {
				this.successResponse = res;
				//console.log('res', res)
				this.$router.push({
					name: 'verification',
					params: {
						user: this.newUser
					}
				})
			}).catch( error => {
				console.log('error', error)
				this.errorResponse = error;
				if (error.code === 409) {
					this.generalError = error.message
				} else
				this.errors = error.errors;
			}).finally(() => {
				this.loading = false;
			})
		}
	},
}
</script>

<style lang="scss" scoped>
.bg-auth {
	height: 100%;
	min-height: 100vh;
	background-color: #7A73FF;
	background-image: url("../../assets/boolink/bg-pattern.svg"), url("../../assets/boolink/sally-hand.png");
	background-repeat: no-repeat;
	background-position: center center, bottom right 20%;
	background-size: contain, auto 90%, auto;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
	.bg-auth {
		background-position: center center, bottom right 300% !important;
		background-size: contain, auto auto, auto;
	}
}

@media only screen and (max-width: 1024px) {
	.bg-auth {
		background-position: center center, bottom right 5%;
		background-size: contain, auto 80%, auto;
	}
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
	.bg-auth {
		background-position: center center, bottom right -300% !important;
		background-size: contain, auto auto, auto;
	}
}

@media only screen and (max-width: 768px) {
	.bg-auth {
		background-position: center center, bottom right -200%;
		background-size: contain, auto auto, auto;
	}
}

@media only screen and (max-width: 576px) {
	.bg-auth {
		background-size: cover, auto auto, auto;
	}
}
</style>
